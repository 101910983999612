import { render, staticRenderFns } from "./SimpleCard.vue?vue&type=template&id=32c9af0a&scoped=true"
import script from "./SimpleCard.vue?vue&type=script&lang=js"
export * from "./SimpleCard.vue?vue&type=script&lang=js"
import style0 from "./SimpleCard.vue?vue&type=style&index=0&id=32c9af0a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c9af0a",
  null
  
)

export default component.exports