<template>
  <div :class="{ card: true, pointer: onClick !== null }" @click="cardClicked">
    <div class="title">
      {{ title }}
    </div>
    <div class="value" :style="{ color: color }">
      <span v-if="isMoney" class="value-prefix" :style="{ color: color }">R$</span>
      <p v-if="isMoney">{{ (isNegative(value) ? '-' : '') + parseNumberToMoney(value).trim().split('$')[1] }}</p>
      <p v-else>{{normalizeProductNumber(value, 2)}}</p>
      <span v-if="percentage" class="tag" v-bind:class="percentage.color">
        <ArrowUp v-if="percentage.icon === 'up'" />
        <ArrowDown v-if="percentage.icon === 'down'" />
        {{parseInt(percentage.value)}} %
      </span>
    </div>
  </div>
</template>
<script>
import ArrowUp from '@/assets/icons/arrow-up.svg'
import ArrowDown from '@/assets/icons/arrow-down.svg'
import { parseNumberToMoney } from '@/utils/moneyHelper';
import { normalizeProductNumber } from '@/utils/product-helper';

export default {
  props: {
   title: { type: String, required: true },
   value: [ Number, String ],
   isMoney: Boolean,
   path: String,
   color: String,
   percentage: Object,
   onClick: Function,
  },
  components: {
    ArrowUp,
    ArrowDown,
  },
  methods: {
    parseNumberToMoney,
    normalizeProductNumber,
    cardClicked(ev) {
      this.onClick && this.onClick(ev);
    },
    isNegative(value){
      return value < 0
    }
  }
}
</script>
<style lang="scss" scoped>
  .card {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    height: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    text-align: left;
    justify-content: center;
    padding: 16px 18px;

    .title {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: var(--dark-blue);
      margin-bottom: 0 !important;
    }

    .value {
      display: flex;
      flex-direction: row;
      gap: 8px;
      font-family: 'Red Hat Display';
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;

      .value-prefix {
        font-size: 18px;
        font-weight: 700;
      }

      .tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        gap: 4px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 130%;
        border-radius: 16px;
        &.blue {
          background-color: var(--blue-500);
          color: var(--neutral-000);
        }
        &.yellow {
          background-color: var(--yellow);
          color: var(--neutral-600);
        }
        &.green {
          background-color: #58D19E;
          color: var(--neutral-000);
        }
        &.red {
          background-color: var(--states-error);
          color: var(--neutral-000);
        }
      }
    }

    @media (min-width: 1440px){
      .value
        {
          font-size: 32px !important;
        }
    }

    @media (max-width: 1439px){
      .value
        {
          font-size: 25px !important;
        }
    }
  }
  .pointer {
    cursor: pointer;
  }
</style>
